import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'

import Layout from '../components/layout'
import Helmet from '../components/helmet'
import NotFound from '../components/404/NotFound'
import Footer from '../components/Footer/Footer'
import EarlyAcess from '../components/EarlyAcess/EarlyAcess'

const NotFoundPage = () => {
  const intl = useIntl()

  return (
    <Layout siteTitle="Onegate">
      <Helmet
        lang={intl.locale}
        pathName={`${intl.locale}/404`}
        title="404: Not found"
      />
      <NotFound />
      <EarlyAcess />
      <Footer />
    </Layout>
  )
}

export default NotFoundPage
