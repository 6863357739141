import React from 'react'
import styles from './NotFound.module.scss'
import notFound from '../../assets/images/notFound.svg'
import Button from '../Button/Button'
import { FormattedMessage } from 'gatsby-plugin-intl'

const NotFound = () => {
  return (
    <div className={`container ${styles.root}`}>
      <div className={styles.section}>
        <img src={notFound} alt="Not found image"></img>
      </div>
      <h3>
        <FormattedMessage id="page404.title404" />
      </h3>
      <a href="/">
        <Button black>
          <FormattedMessage id="page404.button404" />
        </Button>
      </a>
    </div>
  )
}

export default NotFound
